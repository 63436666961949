<template>
  <b-container fluid>
    <b-overlay :show="formLoader">

      <!-- form -->
      <ValidationObserver ref="form">
        <b-form>
          <b-row>
            <b-col sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Team" vid="team_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="team_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('research_manage.team_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="team.team_id"
                        :options="monitoringTeamList"
                        id="team_id"
                        @change="getTeamWithMembers($event)"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Member Count" vid="no_of_members" rules="min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="no_of_members"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('research_manage.no_of_members')}}
                    </template>
                    <b-form-input
                        plain
                        id="no_of_members"
                        v-model="team.members.length"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Organization" vid="org_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="org_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('research_manage.organization_name')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="team.org_id"
                        :options="organizationProfileList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{$t('globalTrans.fiscal_year')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="team.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>

      <!-- form1 -->
      <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset">
          <b-row>

            <b-col sm="12" md="12" lg="12" xl="12">
              <div style="font-size:18px; background-color: #386389;">
                  <h5 class="text-white text-center">
                      {{ `${$t('research_manage.assign_project')}` }}
                  </h5>
              </div>
            </b-col>

            <b-col sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Thematic Area" vid="form_data_thematic_area_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="form_data_thematic_area_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{ $t('research_manage.thematic_area') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.thematic_area_id"
                        :options="thematicAreaList"
                        id="form_data_thematic_area_id"
                        @change="getProjectList($event)"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Project" vid="form_data_project_main_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="form_data_project_main_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                        {{ $t('research_manage.project') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.project_main_id"
                        :options="projectListByThematicArea"
                        id="form_data_project_main_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col sm="12" md="12" lg="12" xl="12">
              <div class="col text-right">
                <b-button type="submit" variant="primary" class="mr-1">{{ $t('globalTrans.add') }}</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <hr>
      </ValidationObserver>

       <!-- form2 -->
      <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-table-simple bordered>
                <thead class="text-white bg-primary">
                  <tr>
                    <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                    <th class="text-center">{{ $t('research_manage.thematic_area') }}</th>
                    <th class="text-center">{{ $t('research_manage.project') }}</th>
                    <th class="text-center">{{ $t('globalTrans.action' )}}</th>
                  </tr>
                </thead>
                <b-tbody>
                  <b-tr v-for="(item, index) in team.projects" :key="item.user_id">
                      <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                      <b-td class="text-center">{{ getThematicAreaName(item.thematic_area_id) }}</b-td>
                      <b-td class="text-center">{{ getProjectFromPrimaryProjectList(item.project_main_id) }}</b-td>
                      <b-td class="text-center">
                        <b-button v-if="item.is_scheduled == 0" type="button" @click="deleteItem(index)" class="btn btn-danger" size="sm">
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                        <span v-if="item.is_scheduled == 1" class="badge badge-success">
                          {{$t('research_manage.scheduled')}}
                        </span>
                      </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>

            <b-col sm="12" md="12" lg="12" xl="12">
              <div class="col text-right">
                <b-button type="submit" variant="primary" class="mr-1">{{ saveBtnName }}</b-button>
                <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>

    </b-overlay>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import {
  monitoringTeamProjectsTeamWithMembers,
  monitoringTeamProjectsApproveProjectList,
  monitoringTeamProjectsStore,
  monitoringTeamProjectsUpdate
} from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
  },
  async mounted () {
    if (this.id) {
      const tmp = this.getDataFromStore()
      if (tmp) {
        this.getTeamWithMembers(tmp.team_id)
      }
    }
  },
  data () {
    return {
      inEditMode: false,
      formLoader: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      team: {
        team_id: 0,
        org_id: 0,
        fiscal_year_id: 0,
        members: [],
        projects: []
      },
      formData: {
        thematic_area_id: 0,
        project_main_id: 0
      },
      projectListByThematicArea: [],
      primaryProjectList: []
    }
  },
  computed: {
    monitoringTeamList: function () {
      return this.$store.state.AgriResearch.commonObj.monitoringTeamList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList
    },
    organizationProfileList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    thematicAreaList: function () {
      return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  methods: {
    getDataFromStore () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return tmpData !== undefined ? JSON.parse(JSON.stringify(tmpData)) : undefined
    },
    addItem () {
      /** Check Unique Projects */
      const project = this.team.projects.find(item => item.project_main_id === this.formData.project_main_id)

      if (project === undefined) {
        const project = this.projectListByThematicArea.find(item => item.project_main_id === this.formData.project_main_id)
        this.primaryProjectList.push(project)
        this.team.projects.push(project)
        this.resetFormData()
      } else {
        const project = this.projectListByThematicArea.find(item => item.project_main_id === this.formData.project_main_id)
        window.vm.$swal({
          icon: 'error',
          title: 'Duplicate Project',
          text: `${this.currentLocale === 'en' ? project.text_en : project.text_bn}`
        })
        this.resetFormData()
      }
    },
    resetFormData () {
      this.formData.project_main_id = 0

      /** Reset Form */
      this.$nextTick(() => {
        this.$refs.form1.reset()
      })
    },
    deleteItem (index) {
      this.primaryProjectList.splice(index, 1)
      this.team.projects.splice(index, 1)
    },
    teamHasExistingProjects () {
      const result = this.team.projects.find((item) => {
        if (item.id && item.id > 0) {
          return true
        } else {
          return false
        }
      })

      return result
    },
    /**
     * To be used in List
     */
    getThematicAreaName (thematicAreaId) {
       const thematicArea = this.thematicAreaList.find(item => item.value === thematicAreaId)
       if (thematicArea === undefined) return ''
       return this.$i18n.locale === 'bn' ? thematicArea.text_bn : thematicArea.text_en
     },
     getProjectFromPrimaryProjectList (projectId) {
       const project = this.primaryProjectList.find(item => item.value === projectId)
       if (project === undefined) return ''
       return this.$i18n.locale === 'bn' ? project.text_bn : project.text_en
     },
    /**
     * Asynchronous
     */
    async getTeamWithMembers (event) {
      const teamId = event
      /** @ change */
      if (teamId > 0) {
        const monitoringTeam = this.monitoringTeamList.find(item => item.value === teamId)
        this.team.team_id = monitoringTeam.value
        this.team.org_id = monitoringTeam.org_id
        this.team.fiscal_year_id = monitoringTeam.fiscal_year_id

        this.formLoader = true
        const result = await RestApi.getData(agriResearchServiceBaseUrl, `${monitoringTeamProjectsTeamWithMembers}/${teamId}`)
        if (result.success) {
          this.team.members = result.data.monitoring_team_members.members
          this.primaryProjectList = result.data.project_details
          this.team.projects = result.data.monitoring_team_members.projects
          this.inEditMode = result.data.monitoring_team_members.projects.length > 0
        } else {
          this.team.members = []
          this.team.projects = []
          this.primaryProjectList = []
          this.inEditMode = false
        }
        this.formLoader = false
      }

      if (teamId <= 0) {
        this.team.team_id = 0
        this.team.org_id = 0
        this.team.fiscal_year_id = 0
        this.team.members = []
        this.team.projects = []
        this.primaryProjectList = []
      }

      /** Validate */
      this.$nextTick(() => {
        this.$refs.form.validate()
      })
    },
    async getProjectList (event) {
      const thematicAreaId = event
      /** @ change */
      if (thematicAreaId > 0) {
        this.formLoader = true
        const result = await RestApi.getData(agriResearchServiceBaseUrl, `${monitoringTeamProjectsApproveProjectList}`, { thematic_area_id: thematicAreaId })
        if (result.success) {
          this.projectListByThematicArea = result.data.map((item) => {
            if (this.currentLocale === 'en') {
              return { ...item, text: item.text_en, is_scheduled: 0 }
            } else {
              return { ...item, text: item.text_bn, is_scheduled: 0 }
            }
          })
        }
        this.formLoader = false
      } else {
        this.projectListByThematicArea.splice(0, this.projectListByThematicArea.length)
      }

      this.formData.project_main_id = 0
    },
    async saveData () {
      const validateForm = await this.$refs.form.validate()
      if (!validateForm) return false

      this.formLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null

      if (this.inEditMode) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${monitoringTeamProjectsUpdate}/${this.team.team_id}`, this.team)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, monitoringTeamProjectsStore, this.team)
      }

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })

        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$store.dispatch('mutateCommonProperties', { loading: false })

        if (result.errors) this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }

      this.formLoader = false
    }
  }
}
</script>
