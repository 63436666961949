<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="detailsLoader">
          <iq-card>
            <template v-slot:body>
              <b-row>

                <b-col sm="12" md="12" lg="12" xl="12">
                  <div style="font-size:18px; background-color: #386389;">
                    <h5 class="text-white text-center">
                        {{ `${$t('research_manage.project')} ${$t('globalTrans.details')}` }}
                    </h5>
                  </div>
                </b-col>

                <b-col v-if="monitoringTeamMembers" md="12" class="table-responsive">
                  <b-table-simple striped bordered small hover>
                    <tbody>
                      <b-tr>
                          <b-th >{{ $t('research_manage.team_name') }}</b-th>
                          <b-td>{{ getMonitoringTeam(monitoringTeamMembers.team_id) }}</b-td>
                          <b-th >{{ $t('research_manage.organization_name') }}</b-th>
                          <b-td>{{ getOrgProfileList(monitoringTeamMembers.org_id) }}</b-td>
                          <b-th >{{ $t('globalTrans.fiscal_year') }}</b-th>
                          <b-td>{{ getFiscalYear(monitoringTeamMembers.fiscal_year_id) }}</b-td>
                      </b-tr>
                    </tbody>
                  </b-table-simple>
                  <b-table-simple striped bordered small hover>
                    <thead>
                      <tr>
                        <th class="text-center" width="100px">{{ $t('globalTrans.sl_no') }}</th>
                        <th class="text-center">{{ $t('research_manage.thematic_area') }}</th>
                        <th class="text-center">{{ $t('research_manage.project') }}</th>
                      </tr>
                    </thead>
                    <b-tbody>
                      <b-tr v-for="(item, index) in monitoringTeamMembers.projects" :key="item.user_id">
                        <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                        <b-td class="text-center">{{ getThematicAreaName(item.thematic_area_id) }}</b-td>
                        <b-td class="text-center">{{ getProjectFromPrimaryProjectList(item.project_main_id) }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { monitoringTeamProjectsShow } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
  },
  created () {
    if (this.id) {
      const tmp = this.getDataFromStore()
      if (tmp) {
        this.getDetailsFromAPI(tmp.team_id)
      }
    }
  },
  mounted () {
  },
  data () {
    return {
      /** nrm_monitoring_team_members */
      monitoringTeamMembers: null,
      primaryProjectList: null,
      detailsLoader: false
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getMonitoringTeam (monitoringId) {
       const team = this.$store.state.AgriResearch.commonObj.monitoringTeamList.find(item => item.value === monitoringId)
       if (team === undefined) return ''
       return this.$i18n.locale === 'bn' ? team.text_bn : team.text_en
     },
     getFiscalYear (fisId) {
      const fisYear = this.$store.state.commonObj.fiscalYearList.find(item => item.value === fisId)
      if (fisYear === undefined) return ''
      return this.$i18n.locale === 'bn' ? fisYear.text_bn : fisYear.text_en
     },
    getOrgProfileList (orgId) {
      const orgName = this.$store.state.commonObj.organizationProfileList.find(item => item.value === orgId)
      if (orgName === undefined) return ''
      return this.$i18n.locale === 'bn' ? orgName.text_bn : orgName.text_en
     },
     getThematicAreaName (thematicAreaId) {
       const thematicArea = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(item => item.value === thematicAreaId)
       if (thematicArea === undefined) return ''
       return this.$i18n.locale === 'bn' ? thematicArea.text_bn : thematicArea.text_en
     },
     getProjectFromPrimaryProjectList (projectId) {
       const project = this.primaryProjectList.find(item => item.value === projectId)
       if (project === undefined) return ''
       return this.$i18n.locale === 'bn' ? project.text_bn : project.text_en
     },
    /**
     * Async
     */
    async getDetailsFromAPI (teamId) {
      this.detailsLoader = true

      const result = await RestApi.getData(agriResearchServiceBaseUrl, `${monitoringTeamProjectsShow}/${teamId}`)
      if (result.success) {
        this.monitoringTeamMembers = result.data.monitoring_team_members
        this.primaryProjectList = result.data.project_details
      }

      this.detailsLoader = false
    },
    /** */
    getDataFromStore () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return tmpData !== undefined ? JSON.parse(JSON.stringify(tmpData)) : undefined
    }
  }
}
</script>
